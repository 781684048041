import React, { useState } from "react";
import { Link } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";

export const MenuItem = ({ href, iconClass, title }) => {
  return (
    <li className="nav-item">
      <Link to={href} className="nav-link">
        <i className={`bi ${iconClass}`}></i>
        {title}
      </Link>
    </li>
  );
};

export const MenuTitle = ({ title }) => {
  return <li className="menu-title">{title}</li>;
};

export const MenuDropDownItem = ({ href, title }) => {
  return (
    <li className="dropdown-item">
      <Link to={href} className="dropdown-link">
        {title}
      </Link>
    </li>
  );
};

export const MenuDropdown = ({
  href,
  iconClass,
  title,
  children,
  hideMenu,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <li className="nav-item dropdown" hidden={!hideMenu}>
      <Link
        to={href}
        onClick={() => setOpen(!open)}
        aria-controls={`#collapse_${title.split(" ").join("")}`}
        aria-expanded={open}
        className={`${!open ? "collapsed" : ""} nav-link`}
      >
        <i className={`bi ${iconClass}`}></i>
        {title}
      </Link>
      <Collapse in={open}>
        <div id={`collapse_${title.split(" ").join("")}`}>
          <ul className="sub-nav">{children}</ul>
        </div>
      </Collapse>
    </li>
  );
};
