import React, { useEffect, useMemo } from "react";
import ProgressBar from "../Components/Core/ProgressBar";
import Logo from "../assets/images/logo-light.png";
import PerfectScrollbar from "perfect-scrollbar";
import {
  MenuItem,
  MenuDropdown,
  MenuDropDownItem,
  MenuTitle,
} from "../Components/Core/MenuItem";
import { useContext } from "react";
import GlobalContext from "../utils/GlobalContext";
import { Link } from "react-router-dom";

const menuItemsAndLinks = [
  {
    type: "dropdown",
    name: "Users",
    icon: "bi-people-fill",
    permission: ["super"],
    Users: [{ "Add New": "/user/add" }, { "All Users": "/users" }],
  },
  {
    type: "dropdown",
    name: "Categories",
    icon: "bi-diagram-3",
    permission: ["admin", "super"],
    Categories: [
      { "Add New": "/category/add?type=blog" },
      { "All Categories": "/categories" },
      { "Deleted Categories": "/category/deleted" },
    ],
  },
  {
    type: "dropdown",
    name: "Articles",
    icon: "bi-file-earmark-richtext",
    permission: ["admin", "super"],
    Articles: [
      { "Add New": "/article/add" },
      { "All Articles": "/articles" },
      { "Deleted Articles": "/article/deleted" },
    ],
  },
  {
    type: "dropdown",
    name: "Menu",
    icon: "bi-menu-app-fill",
    permission: ["admin", "super"],
    Menu: [
      { "Manage Menu": "/menus" },
      { "Add New": "/menu/add" },
      { "Deleted Menu": "/menu/deleted" },
    ],
  },

  {
    type: "dropdown",
    name: "Team",
    icon: "bi-people",
    permission: ["admin", "super"],
    Team: [
      { "Add New": "/team/add" },
      { "Team Members": "/teams" },
      { "Deleted Members": "/team/deleted" },
    ],
  },

  {
    type: "dropdown",
    name: "Reviews",
    icon: "bi-chat-left-quote",
    permission: ["admin", "super"],
    Reviews: [
      { "Add New": "/testimonial/add" },
      { "Review List": "/testimonials" },
      { "Deleted Review": "/testimonial/deleted" },
    ],
  },

  {
    type: "dropdown",
    name: "FAQS",
    icon: "bi-question-circle",
    permission: ["admin", "super"],
    FAQS: [
      { "Add New": "/faq/add" },
      { "Faqs List": "/faqs" },
      { "Deleted Faqs": "/faq/deleted" },
    ],
  },

  {
    type: "title",
    name: "Blog",
  },
  {
    type: "dropdown",
    name: "Authors",
    icon: "bi-person-lines-fill",
    permission: ["admin", "super"],
    Authors: [
      { "Add New": "/blog/author/add" },
      { "All Authors": "/blog/authors" },
      { "Deleted Authors": "/blog/author/deleted" },
    ],
  },

  {
    type: "dropdown",
    name: "Blogs",
    icon: "bi-pencil-square",
    permission: ["admin", "super"],
    Blogs: [
      { "Add New": "/blog/add" },
      { "All Blogs": "/blogs" },
      { "Deleted Blogs": "/blog/deleted" },
    ],
  },

  {
    type: "dropdown",
    name: "Comments",
    permission: ["admin", "super"],
    icon: "bi-chat-quote",
    Comments: [
      { "Add New": "/blog/comment/add" },
      { "All Comments": "/blog/comments" },
      { "Deleted Comments": "/blog/comment/deleted" },
    ],
  },

  {
    type: "title",
    name: "Travel",
  },
  {
    type: "dropdown",
    name: "Package",
    icon: "bi-compass",
    permission: ["admin", "super"],
    Package: [
      { "Add New": "/package/add" },
      { "All Packages": "/packages" },
      { "Deleted Packages": "/package/deleted" },
    ],
  },

  // {
  //   type: "dropdown",
  //   name: "Itinerary",
  //   icon: "bi-geo-alt",
  //   permission: ["admin", "super"],
  //   Itinerary: [
  //     { "Add New": "/itinerary/add" },
  //     { "All Itineraries": "/itineraries" },
  //     { "Deleted Itineraries": "/itinerary/deleted" },
  //   ],
  // },
  // {
  //   type: "dropdown",
  //   name: "Departure",
  //   icon: "bi-calendar-check",
  //   permission: ["admin", "super"],
  //   Departure: [
  //     //{ "Add New": "/departure/add" },
  //     { "All Date": "/departures" },
  //   ],
  // },
  {
    type: "dropdown",
    name: "Tripnote",
    icon: "bi-info-circle",
    permission: ["admin", "super"],
    Tripnote: [
      { "Add New": "/tripinfo/add" },
      { "All Trip Infos": "/tripinfos" },
      { "Deleted Trip Infos": "/tripinfo/deleted" },
    ],
  },
  {
    type: "dropdown",
    name: "Reels",
    icon: "bi-film",
    permission: ["admin", "super"],
    Reels: [{ "Add New": "/reel/add" }, { "All Reels": "/reels" }],
  },
  {
    type: "dropdown",
    name: "Booking",
    icon: "bi-basket3-fill",
    permission: ["super"],
    Booking: [
      { "Create Booking": "/booking/add" },
      { "All Booking": "/bookings" },
      { "All inquiry": "/inquiries" },
    ],
  },

  {
    type: "dropdown",
    name: "Cart",
    icon: "bi-cart",
    permission: ["super"],
    Cart: [{ "Create a Cart": "/payment/add" }, { "All Cart": "/payments" }],
  },
  {
    type: "title",
    name: "Stories",
  },
  {
    type: "dropdown",
    name: "Author",
    icon: "bi-person-bounding-box",
    permission: ["admin", "super"],
    Author: [
      { "Add New": "/story-author/add" },
      { "All Author": "/story-authors" },
      { "Deleted Authors": "/story-author/deleted" },
    ],
  },
  {
    type: "dropdown",
    name: "Story",
    icon: "bi-bookmark-heart",
    permission: ["admin", "super"],
    Story: [
      { "Add New": "/story/add" },
      { "All Story": "/stories" },
      { "Deleted Story": "/story/deleted" },
    ],
  },
  {
    type: "title",
    name: "Media",
  },
  {
    type: "dropdown",
    name: "Album",
    icon: "bi-easel",
    permission: ["admin", "super"],
    Album: [
      { "Add New": "/album/add" },
      { "All Album": "/albums" },
      { "Deleted Album": "/album/deleted" },
    ],
  },
  {
    type: "dropdown",
    name: "Media",
    icon: "bi-collection-play",
    permission: ["admin", "super"],
    Media: [
      { "Add New": "/media/add" },
      { "All Media": "/allmedia" },
      { "Deleted Media": "/media/deleted" },
    ],
  },
];

const Sidebar = () => {
  const globalData = useContext(GlobalContext);
  const { sidebar_skin, logo_light, company_name } = globalData || {};

  useEffect(() => {
    const container = document.querySelector("#sidebar-scroll-container");
    const ps = new PerfectScrollbar(container);

    return () => {
      ps.destroy(); // Cleanup PerfectScrollbar instance
    };
  }, []);

  // Memoize menu rendering for performance
  const renderedMenuItems = useMemo(() => {
    if (!menuItemsAndLinks || menuItemsAndLinks.length === 0) {
      return <li>No menu items available</li>;
    }

    return menuItemsAndLinks.map((menuItem, idx) => {
      switch (menuItem.type) {
        case "dropdown":
          return (
            <MenuDropdown
              key={idx}
              href={menuItem[menuItem.name]}
              iconClass={menuItem.icon}
              title={menuItem.name}
              hideMenu={menuItem?.permission?.includes(
                localStorage.getItem("admin_type")
              )}
            >
              {menuItem[menuItem.name].map((subMenu, jdx) => (
                <MenuDropDownItem
                  key={jdx}
                  href={subMenu[Object.keys(subMenu)[0]]}
                  title={Object.keys(subMenu)[0]}
                />
              ))}
            </MenuDropdown>
          );
        case "title":
          return <MenuTitle key={menuItem.name} title={menuItem.name} />;
        case "link":
          return (
            <MenuItem
              key={menuItem.name}
              href={menuItem[menuItem.name]}
              iconClass={menuItem.icon}
              title={menuItem.name}
            />
          );
        default:
          return null;
      }
    });
  }, [menuItemsAndLinks]);

  return (
    <aside className={`sidebar ${sidebar_skin || "dark"}`}>
      <div className="sidebar-container">
        <div className="sidebar-header">
          <Link className="navbar-brand" to="/">
            <img
              src={logo_light ? `${process.env.REACT_APP_IMAGE_URL}${logo_light}` : Logo}
              alt={company_name || "Flamingo IT Studio"}
            />
          </Link>
          <div className="data-usage">
            <ProgressBar percentValue={65} fact="4.5 / 5" description="progress" />
          </div>
        </div>

        <div
          className="sidebar-body"
          id="sidebar-scroll-container"
          data-perfact-scrollbar="true"
        >
          <nav>
            <ul className="navbar-nav">
              <li className="menu-title">Menu</li>
              {renderedMenuItems}
            </ul>
          </nav>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
