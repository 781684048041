import axios from "axios";
import React, { createContext, useEffect, useState, useMemo } from "react";

const GlobalContext = createContext(null);
export default GlobalContext;

export const GlobalDataProvider = ({ children }) => {
  const [globalData, setGlobalData] = useState(null);

  useEffect(() => {
    const fetchGlobalData = async () => {
      try {
        const { data } = await axios.get("/global"); 
        setGlobalData(data?.data);
      } catch (error) {
        console.error("Failed to fetch global data:", error.message || error);
      }
    };

    fetchGlobalData(); 
  }, []);

  const contextValue = useMemo(() => globalData, [globalData]);

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
