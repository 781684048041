import axios from "axios";
import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";

import useIdleLogout from "../hooks/useIdleLogout";
import { selectCurrentToken, logOut as clearToken } from "../redux/slice/auth";

const RequireAuth = () => {
  const token = useSelector(selectCurrentToken) || localStorage.getItem("access_token");
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_BASE_URL}api/v1/admin/auth/logout`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      console.log("Logout successful");
    } catch (err) {
      message.error("Failed to log out");
      console.error("Logout error:", err);
    } finally {
      dispatch(clearToken());
      navigate("/login", { replace: true });
    }
  };

  // Setup idle logout handler
  useIdleLogout(handleLogout);

  return token ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />;
};

export default RequireAuth;
